<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="contentHeight"
          :param.sync="popupParam"
          @updateMode="updateMode"
          @updateProjectInfo="updateData"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import { uid } from 'quasar';
export default {
  name: 'project-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        projectAreaFlag: '',
        projectBookFlag: '',
        updateFlag: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'tab1',
      tabItems: [
        { name: 'tab1', label: '기본정보', icon:'info', class:'tab-lightblue', component: () => import(`${'./projectSafetyOrg.vue'}`), key: uid(), },
      ],
      addTabItems: [
        { name: 'tab1', label: '기본정보', icon:'info', class:'tab-lightblue', component: () => import(`${'./projectSafetyOrg.vue'}`), key: uid(), },
        // { name: 'tab2', label: '이해관계사', icon:'people_alt', component: () => import(`${'./projectStackHolder.vue'}`), key: uid(),},
        { name: 'tab3', label: '공종 및 도급업체', icon:'account_tree', component: () => import(`${'./projectProcess.vue'}`), key: uid(), },
        { name: 'tab6', label: '참여업체 정보', icon:'person_add', component: () => import(`${'./projectAttendTab.vue'}`), key: uid(), },
        { name: 'tab5', label: '안전보건관리대장', icon:'book', component: () => import(`${'./projectBook.vue'}`), key: uid(),},
        { name: 'tab4', label: '현장지도', icon:'place', component: () => import(`${'./projectLocation.vue'}`), key: uid(), },
        { name: 'tab7', label: '기준정보 관리', icon:'settings', component: () => import(`${'./cmd/projectCommonDataManage.vue'}`), key: uid(), },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    tabHeight() {
      return String(this.contentHeight - 50);
    },
  },
  watch: {},
  methods: {
    init() {
      if (this.popupParam.plantCd) {
        this.tabItems = this.addTabItems;

        if (this.$store.getters.user.innerFlag === 'N') {
          this.tab = 'tab6'
        }
      }
    },
    updateMode(_plantCd) {
      this.popupParam.plantCd = _plantCd;
      this.tabItems = this.addTabItems;
    },
    updateData() {
      // this.$set(this.popupParam, 'updateFlag', uid())
    },
    tabClick() {
      this.$set(this.popupParam, 'updateFlag', uid())
    },
  },
};
</script>